import React, {
  useEffect, useState, useRef,
} from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Message, Icon } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'
import to from 'await-to-js'

import ResetPasswordForm from '../../forms/reset-password/reset-password'
import LoginView from '../login/login'
import fetch from '../../../helpers/fetch'
import {
  HTTP_METHODS,
} from '../../../helpers/fetch-constants'

import './reset-password.css'

const requestPasswordReset = async (data) => {
  const [ err ] = await to(fetch('/secretResetRequests', { method: HTTP_METHODS.POST, data }))
  if (err) {
    throw err
  }
  return true
}

const ResetPasswordView = ({
  t,
}) => {
  const [ success, setSuccess ] = useState(false)
  const [ error, setError ] = useState()
  const [ isFetching, setIsFetching ] = useState(false)
  const [ isCancelled, setIsCancelled ] = useState(false)

  // Runs once, and cancels async requests on unmount
  useEffect(() => {
    return function cleanUp () {
      setIsCancelled(true)
    }
  }, [])

  const handleReset = useRef((credentials) => {
    if (isCancelled) {
      return
    }
    setError(null)
    setSuccess(false)
    setIsFetching(true)

    requestPasswordReset(credentials)
      .then((auth) => {
        if (isCancelled) {
          return
        }
        setIsFetching(false)
        setSuccess(true)
      })
      .catch((ex) => {
        if (isCancelled) {
          return
        }
        setIsFetching(false)
        setError(ex)
      })
  })

  const errorStatus = get(error, 'statusCode')
  return (
    <div className='reset-password-page'>
      <ResetPasswordForm
        onResetPassword={handleReset.current}
        isLoading={isFetching}
        showNotConnectedError={errorStatus === 0}
        showBadRequestError={errorStatus === 400}
        showNotFoundError={errorStatus === 404}
        showGeneralError={errorStatus >= 500}
        showSuccessMessage={success}/>
      <Message data-public icon>
        <Icon name='info circle' />
        <Message.Content>
          <Message.Header>
            {t('views.reset-password.know_password')}
          </Message.Header>
          <p><a href={`#${LoginView.path}`}>{t('views.reset-password.click_here')}</a></p>
        </Message.Content>
      </Message>
    </div>
  )
}

ResetPasswordView.propTypes = {
  location: PropTypes.object.isRequired,
  t: PropTypes.func,
  history: PropTypes.object.isRequired,
}

const ResetPasswordViewContainer = withTranslation([ 'components' ])(ResetPasswordView)

ResetPasswordView.path = '/reset-password'
ResetPasswordViewContainer.path = '/reset-password'

export default ResetPasswordViewContainer

import React from 'react'
import ReactDOM from 'react-dom'
import {
  Route,
  Switch,
  Redirect,
} from 'react-router'
import { HashRouter } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import { GoogleOAuthProvider } from '@react-oauth/google'

import config from './config'
import i18n from './i18n'
import LoginView from './components/views/login/login'
import ResetPasswordView from './components/views/reset-password/reset-password'
import SetPasswordView from './components/views/set-password/set-password'
import TopLevelErrorBoundary from './components/common/top-level-error-boundary/top-level-error-boundary'

import './index.css'
import 'semantic-ui-css/semantic.min.css'

// reload the site once in a while when the page loses focus to force the browser to load the latest version of the site
let intervalHandle
const checkLastLoad = () => {
  const lastLoadTime = (window.localStorage.getItem('education.base.login.last-load-time') || 0) * 1
  const now = new Date().getTime()
  const RELOAD_INTERVAL = 1000 * 60 * 60 * 4 // every 4 hours
  if (now - lastLoadTime > RELOAD_INTERVAL) {
    let count = 0
    // check for losing browser focus for 10 seconds straight
    setInterval(() => {
      if (!document.hasFocus()) {
        count++
        if (count > 10) {
          console.log(`Last load time was: ${new Date(lastLoadTime).toISOString()}. Reloading page...`)
          window.localStorage.setItem('education.base.login.last-load-time', new Date().getTime())
          window.location.reload()
        }
      } else {
        count = 0
      }
    }, 1000)
    clearInterval(intervalHandle)
    intervalHandle = null
  }
}
checkLastLoad()
intervalHandle = setInterval(checkLastLoad, 1000 * 60 * 60)

ReactDOM.render(
  <TopLevelErrorBoundary redirectOnError={config.redirectOnError}>
    <GoogleOAuthProvider clientId={config.googleSsoClientId}>
      <HashRouter>
        <I18nextProvider i18n={i18n}>
          <Switch>
            <Route exact path={LoginView.path} component={LoginView} />
            <Route path={ResetPasswordView.path} component={ResetPasswordView}/>
            <Route path={SetPasswordView.path} component={SetPasswordView}/>
            <Redirect to={LoginView.path} />
          </Switch>
        </I18nextProvider>
      </HashRouter>
    </GoogleOAuthProvider>
  </TopLevelErrorBoundary>,
  document.getElementById('root'),
)

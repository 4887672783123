import {
  isArray, isString, isNil, isNumber, isDate,
} from 'lodash'
// Note: These validators are used with react-final-form which expects
// them to return undefined if the value is valid, and an error if invalid.
// Instead of returning error objects, let's return normal objects that
// contain the desired error message (or the translation key) so that it
// can be translated at render time.

export const minLength = ({ message, count }) => (value, allValues) => {
  if (isNil(value)) {
    return
  }
  return (value.length >= count)
    ? undefined
    : { message, count }
}

export const maxLength = ({ message, count }) => (value, allValues) => {
  if (isNil(value)) {
    return
  }
  return (value.length <= count)
    ? undefined
    : { message, count }
}

export const pattern = ({ message, pattern }) => (value, allValues) => {
  return (!value || value.match(pattern))
    ? undefined
    : { message }
}

// makes sure each line of input matches the pattern (the 'm' flag is not necessary)
export const multilinePattern = ({ message, pattern }) => (value, allValues) => {
  if (!value) {
    return
  }
  const lines = value.split(/\r?\n/)
  const invalidLineNumbers = lines.reduce((accum, line, idx) => (!line.match(pattern)) ? accum.concat([ idx + 1 ]) : accum, []).join(', ')
  return (!invalidLineNumbers)
    ? undefined
    : { message, invalidLineNumbers }
}

export const multilineMinLines = ({ message, count }) => (value, allValues) => {
  if (!value) {
    return
  }
  const lines = value.split(/\r?\n/)
  const isValid = lines.length >= count
  return (isValid)
    ? undefined
    : { message, count }
}

export const multilineMaxLines = ({ message, count }) => (value, allValues) => {
  if (!value) {
    return
  }
  const lines = value.split(/\r?\n/)
  const isValid = lines.length <= count
  return (isValid)
    ? undefined
    : { message, count }
}

export const matchesField = ({
  message,
  fieldName,
  fields,
}) => (value, allValues) => {
  if (isNil(value) || value.length < 1 || isNil(allValues[fieldName]) || allValues[fieldName].length < 1) {
    return
  }
  return (value === allValues[fieldName])
    ? undefined
    : { message, fields }
}

export const required = ({ message }) => (value, allValues) => {
  return (((isArray(value) || isString(value)) && value.length) || isNumber(value) || isDate(value)) ? undefined : { message }
}

export const composeValidators = (...validators) => (value, allValues) => {
  return validators.reduce((error, validator) => error || validator(value, allValues), undefined)
}

export const composeObjectValidators = (obj) => (value, allValues) => {
  return Object.keys(obj).reduce((error, key) => error || obj[key](value[key], allValues), undefined)
}

import querystring from 'querystring'

import React, {
  useEffect,
  useState,
  useRef,
} from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Message, Icon } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'
import to from 'await-to-js'

import SetPasswordForm from '../../forms/set-password/set-password'
import LoginView from '../login/login'
import fetch from '../../../helpers/fetch'
import {
  HTTP_METHODS,
} from '../../../helpers/fetch-constants'

import './set-password.css'

const resetToken = querystring.parse(window.location.search.replace('?', '')).token || ''
const requestPasswordReset = async (newSecret) => {
  const [ err ] = await to(fetch('/secretReset', { method: HTTP_METHODS.POST, data: { resetToken, newSecret } }))
  if (err) {
    throw err
  }
  return true
}

const SetPasswordView = ({
  t,
}) => {
  const [ success, setSuccess ] = useState(false)
  const [ error, setError ] = useState()
  const [ isFetching, setIsFetching ] = useState(false)
  const [ isCancelled, setIsCancelled ] = useState(false)

  // Runs once, and cancels async requests on unmount
  useEffect(() => {
    return function cleanUp () {
      setIsCancelled(true)
    }
  }, [])

  const handleSetPassowrd = useRef((data) => {
    if (isCancelled) {
      return
    }
    setError(null)
    setSuccess(false)
    setIsFetching(true)

    requestPasswordReset(data.newSecret)
      .then((auth) => {
        if (isCancelled) {
          return
        }
        setIsFetching(false)
        setSuccess(true)
      })
      .catch((ex) => {
        if (isCancelled) {
          return
        }
        setIsFetching(false)
        setError(ex)
      })
  })

  const errorStatus = get(error, 'statusCode')
  return (
    <div className='set-password-page'>
      <SetPasswordForm
        onSetPassword={handleSetPassowrd.current}
        isLoading={isFetching}
        showNotValidError={errorStatus === 400 || errorStatus === 500 || !resetToken}
        showNotConnectedError={errorStatus === 0}
      />
      <Message positive icon hidden={!success}>
        <Icon name='info circle' />
        <Message.Content>
          <Message.Header>
            {t('views.set-password.success')}
          </Message.Header>
          <p><a href={`#${LoginView.path}`}>{t('views.set-password.click_here')}</a></p>
        </Message.Content>
      </Message>
    </div>
  )
}

SetPasswordView.propTypes = {
  t: PropTypes.func,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

SetPasswordView.path = '/set-password'

const SetPasswordViewContainer = withTranslation([ 'components' ])(SetPasswordView)
SetPasswordViewContainer.path = '/set-password'

export default SetPasswordViewContainer

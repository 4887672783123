import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Message, Icon } from 'semantic-ui-react'
import { get } from 'lodash'

const TRANSLATION_PREFIX = 'common.fetch-result-message'

const FetchResultMessage = ({
  success,
  error,
  itemType,
  showFriendlyError,
  onDismiss,
  friendlyErrorOverrides,
  customSuccessMessage,
  t,
}) => {
  if (error) {
    console.error(error)
  }
  const messageHeader = t(`${TRANSLATION_PREFIX}.header.${(success) ? 'success' : 'error'}`)
  const errorStatus = get(error, 'statusCode', -1)
  const errorMsg = get(error, 'message', null)
  const messageContent = (success && customSuccessMessage)
    ? customSuccessMessage
    : (success)
      ? t(`${TRANSLATION_PREFIX}.message.success`, { data: itemType, count: (itemType.endsWith('s')) ? 2 : 1 })
      : (!showFriendlyError)
        ? errorMsg
        : (friendlyErrorOverrides && friendlyErrorOverrides[errorStatus])
          ? friendlyErrorOverrides[errorStatus]
          : t([ `${TRANSLATION_PREFIX}.message.error.${errorStatus}`, `${TRANSLATION_PREFIX}.message.error.unknown` ])

  return (
    <Message
      icon
      negative={!success}
      positive={success}
      hidden={!(success || error)}
      onDismiss={onDismiss}>
      <Icon name={(success) ? 'checkmark' : 'warning'} />
      <Message.Content>
        <Message.Header data-public>
          {messageHeader}
        </Message.Header>
        <p data-public>{messageContent}</p>
      </Message.Content>
    </Message>
  )
}

FetchResultMessage.propTypes = {
  success: PropTypes.bool.isRequired,
  error: PropTypes.object,
  itemType: PropTypes.string,
  showFriendlyError: PropTypes.bool,
  onDismiss: PropTypes.func,
  friendlyErrorOverrides: PropTypes.object,
  customSuccessMessage: PropTypes.string,
  t: PropTypes.func.isRequired,
}

FetchResultMessage.defaultProps = {
  showFriendlyError: true,
  itemType: 'data',
}

export default withTranslation([ 'components' ])(FetchResultMessage)

import axios from 'axios'
import to from 'await-to-js'
import httperr from 'httperr'
import { get } from 'lodash'

import config from '../config'

const instance = axios.create({
  baseURL: config.api.baseUrl,
  timeout: config.api.timeoutSeconds * 1000, // timeoutSeconds is in config.js
})
const gqlInstance = axios.create({
  baseURL: config.api.baseGraphQlUrl,
  timeout: config.api.timeoutSeconds * 1000, // timeoutSeconds is in config.js
})
const UnknownNetworkError = httperr.createHttpError(0, 'Unknown Network Error')

export const getCancelToken = () => {
  const CancelToken = axios.CancelToken
  return CancelToken.source()
}

export const wasCancelled = (error) => axios.isCancel(error)

export default async function fetch (url, config) {
  let [ err, result ] = await to(instance(url, config))
  if (result && result.status >= 500) {
    [ err, result ] = await to(instance(url, config))
  }
  return handleFetchResults(err, result, url)
}

export const fetchGraphQl = async (headers, operationName, variables, query) => {
  const [ err, result ] = await to(gqlInstance('/graphql', {
    method: 'post',
    headers,
    data: {
      operationName,
      variables,
      query,
    },
  }))
  return handleFetchResults(err, result, '/graphql')
}

// export for unit testing
export const handleFetchResults = (err, result, url) => {
  if (err) {
    if (wasCancelled(err)) {
      throw err
    }
    if (err.status === 0 || !err.response) {
      throw new UnknownNetworkError(err.message)
    }
    const errorMessage = get(err, 'response.data.message', err.message || 'Unknown Error')
    const errorStatus = get(err, 'response.status')
    // if (window.ga) {
    //   try {
    //     window.ga('send', 'exception', {
    //       exDescription: errorMessage + ' - ' + errorStatus + ' (' + url + ')',
    //       exFatal: false,
    //     })
    //   } catch (e) {
    //   }
    // }
    throw httperr[errorStatus](errorMessage)
  }
  if (result.status === 201 && result.headers.location) {
    return result.headers.location.split('/').pop()
  }
  return result.data
}

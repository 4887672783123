import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Image, Segment, Input, Label, Button,
} from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'
import { Form, Field } from 'react-final-form'

import {
  minLength, matchesField, composeValidators,
} from '../../../helpers/form-validators'
import FetchResultMessage from '../../common/fetch-result-message/fetch-result-message'

import './set-password.css'

const validateNewSecret = composeValidators(
  minLength({ message: 'forms.error_min_length', count: 7 }),
  matchesField({
    message: 'forms.error_matches_field',
    fieldName: 'newSecretAgain',
    fields: 'passwords',
  }),
)

const validateNewSecretAgain = composeValidators(
  minLength({ message: 'forms.error_min_length', count: 7 }),
  matchesField({
    message: 'forms.error_matches_field',
    fieldName: 'newSecret',
    fields: 'passwords',
  }),
)

export class SetPasswordForm extends Component {
  static propTypes = {
    onSetPassword: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    showNotConnectedError: PropTypes.bool,
    showNotValidError: PropTypes.bool,
    t: PropTypes.func,
  }

  static defaultProps = {
    onSetPassword: console.log.bind(console, 'onSetPassword'),
    color: 'teal',
    isLoading: false,
    t: (key, opts = {}) => opts.defaultValue || key,
  }

  handleSetPassword = ({ newSecret }) => {
    this.props.onSetPassword({ newSecret })
  }

  render () {
    const {
      color,
      isLoading,
      showNotConnectedError,
      showNotValidError,
      t,
    } = this.props
    const newPasswordText = `${t('new', { postProcess: 'titleCase' })} ${t('forms.password_label', { postProcess: 'titleCase' })}`
    const reenterPasswordText = `${t('re_enter')} ${t('forms.password_label', { postProcess: 'titleCase' })}`

    return (
      <Form
        onSubmit={this.handleSetPassword}
        subscription={{ pristine: true, invalid: true }}
        initialValues={{ newSecret: '', newSecretAgain: '' }}
        render={({
          handleSubmit,
          pristine,
          invalid,
        }) => (
          <form className='set-password-form' onSubmit={handleSubmit}>
            <Segment attached='top'>
              <Image src='https://media.base.education/img/base-logo.svg' size='medium' centered />
            </Segment>
            <Segment attached inverted color='black'>
              <Field
                name='newSecret'
                validate={validateNewSecret}
                validateFields={[ 'newSecret', 'newSecretAgain' ]}
                render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                  <div>
                    <Input
                      className='reset-password-input'
                      placeholder={newPasswordText}
                      error={!!message && touched}
                      type='password'
                      label={{ icon: 'lock', color }}
                      labelPosition='left'
                      fluid
                      disabled={isLoading}
                      {...input}
                      autoCorrect='off'
                      autoCapitalize='none'
                      spellCheck='false'
                    />
                    {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                  </div>
                )}
              />
              <br/>
              <Field
                name='newSecretAgain'
                validate={validateNewSecretAgain}
                validateFields={[ 'newSecret', 'newSecretAgain' ]}
                render={({ input, meta: { error: { message, ...options } = {}, touched } }) => (
                  <div>
                    <Input
                      className='reset-password-input'
                      placeholder={reenterPasswordText}
                      error={!!message && touched}
                      type='password'
                      label={{ icon: 'lock', color }}
                      labelPosition='left'
                      fluid
                      disabled={isLoading}
                      {...input}
                      autoCorrect='off'
                      autoCapitalize='none'
                      spellCheck='false'
                    />
                    {message && touched && <Label data-public color='red' basic pointing>{t(message, options)}</Label>}
                  </div>
                )}
              />
              {(showNotValidError || showNotConnectedError) && <br/>}
              {(showNotValidError || showNotConnectedError) && <FetchResultMessage
                error={{ statusCode: 0, message: t('forms.set-password.invalid_form') }}
                success={false}
                showFriendlyError={!showNotValidError}
              />}
            </Segment>
            <Segment className='bottom-segment' attached='bottom'>
              <Button
                type='submit'
                className='set-password-button'
                loading={isLoading}
                color={color}
                disabled={isLoading || pristine || invalid}>
                {t('forms.save_button')}
              </Button>
            </Segment>
          </form>
        )}
      />
    )
  }
}

export default withTranslation([ 'components' ])(SetPasswordForm)

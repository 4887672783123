const config = {
  api: {
    baseUrl: process.env.REACT_APP_API_BASE_URL || 'https://api.base.education',
    baseGraphQlUrl: process.env.REACT_APP_GRAPHQL_API_BASE_URL || 'https://api-prd.base.education/prd',
    timeoutSeconds: 30,
  },
  showFriendlyApiErrorMessages: true,
  logStoreEvents: true,
  redirectOnError: false,
  googleSsoClientId: '646365707255-rbl2jruhu2jsqi355bju7bk32hmi9qk6.apps.googleusercontent.com',
  unifiedLoginUrl: process.env.REACT_APP_UNIFIED_LOGIN_URL || 'https://www.7mindsetsportal.com/login/',
}

export default config

import React, {
  useEffect, useState, useRef,
} from 'react'
import PropTypes from 'prop-types'
import {
  get, omit, fromPairs,
} from 'lodash'
import {
  Message,
  Icon,
  Image,
  Segment,
  Header,
  Label,
  Popup,
  Button,
} from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'
import to from 'await-to-js'
import localforage from 'localforage'
import { GoogleLogin } from '@react-oauth/google'

import fetch from '../../../helpers/fetch'
import {
  HTTP_METHODS,
} from '../../../helpers/fetch-constants'
import isAuthenticated from '../../../helpers/is-authenticated'
import LoginForm from '../../forms/login/login'
import ResetPasswordVeiw from '../reset-password/reset-password'
import FullScreenLoadingOverlay from '../../common/full-screen-loading-overlay'
import config from '../../../config'

import './login.css'

const TOKEN_PATH = '/token'
const SSO_TOKEN_PATHS = {
  classlink: '/classlink/token',
  clever: '/clever/token',
  google: '/google/token',
}
const GRANT_TYPES = {
  PASSWORD: 'password',
  REFRESH: 'refresh_token',
}
const params = new URLSearchParams(window.location.search.replace(/^\?/, ''))
const queryParams = fromPairs(Array.from(params.entries()))
const hostnamePrefix = window.location.hostname.replace('login.base.education', '')
const APPS = {
  ADMIN: (queryParams.prefix) ? `${queryParams.prefix}.admin` : (hostnamePrefix) ? `${hostnamePrefix}admin` : 'admin',
  COURSE: (queryParams.prefix) ? `${queryParams.prefix}.course` : (hostnamePrefix) ? `${hostnamePrefix}course` : 'course',
}
const SSOS = {
  CLASS_LINK: 'ClassLink',
  CLEVER: 'Clever',
}
const SSO_CLIENT_IDS = {
  [SSOS.CLASS_LINK]: 'c1574266528950e18195ff0a85d468be26e9162efb0b76',
  [SSOS.CLEVER]: '217a7a8fa41c2965c02c',
}
const LOCAL_STORAGE_KEYS = {
  AUTH: 'auth',
  SSO: 'sso',
}
const KEY_CODES = [
  13, // Enter
  32, // Space
]
const COURSE_ONLY_ROLE_TYPES = [ 'adult', 'student' ]
const DB_NAME = 'login.base.education'
const localStore = localforage.createInstance({ name: DB_NAME })

const login = async ({ userName, password }) => {
  const data = new URLSearchParams({
    grant_type: GRANT_TYPES.PASSWORD,
    username: userName,
    password,
  }).toString()
  const [ err, result ] = await to(fetch(TOKEN_PATH, { method: HTTP_METHODS.POST, data }))
  if (err) {
    throw err
  }
  const [ userId, orgId, roleType ] = result.access_token.split(':')
  return {
    accessToken: result.access_token,
    refreshToken: result.refresh_token,
    userId,
    orgId,
    roleType,
    expires: Date.now() + (result.expires_in * 1000),
  }
}

const loginViaSSO = async ({ code, ssoName }) => {
  const data = {
    code,
  }
  const [ err, result ] = await to(fetch(SSO_TOKEN_PATHS[ssoName.toLowerCase()], { method: HTTP_METHODS.POST, data }))
  if (err) {
    throw err
  }
  const [ userId, orgId, roleType ] = result.access_token.split(':')
  return {
    accessToken: result.access_token,
    refreshToken: result.refresh_token,
    userId,
    orgId,
    roleType,
    expires: Date.now() + (result.expires_in * 1000),
  }
}

const postLogs = async (logs) => {
  const hasAtLeastOneErrorLog = logs.some((l) => l.isError)
  if (!hasAtLeastOneErrorLog) {
    return false
  }
  const data = {
    logs,
  }
  const [ err ] = await to(fetch('/authlogs', { method: HTTP_METHODS.POST, data }))
  if (err) {
    console.error('Unable to send auth logs', err)
    return false
  }
  return true
}

let logs = []

const launchApp = async (app, auth, launchCourseId) => {
  // NOTE: The forward slash before the ?auth= is needed for admin, otherwise it tries to redirect to / immediately without the auth info
  const launchUrl = (launchCourseId)
    ? `https://${app}.base.education/?auth=${window.btoa(JSON.stringify({ access_token: auth.accessToken, refresh_token: auth.refreshToken }))}&launch_course_id=${launchCourseId}`
    : `https://${app}.base.education/?auth=${window.btoa(JSON.stringify({ access_token: auth.accessToken, refresh_token: auth.refreshToken }))}`
  const [ postErr ] = await to(postLogs(logs))
  if (postErr) {
    console.error('Unable to post auth logs', postErr)
  }
  logs = []
  const [ err ] = await to(localStore.clear())
  if (err) {
    console.error('Unable to clear local storage', err)
  }

  window.location.href = launchUrl
}

export const LoginView = ({
  t,
}) => {
  const [ initialLoad, setInitialLoad ] = useState(true)
  const [ selectedApp, setSelectedApp ] = useState(null)
  const [ auth, setAuth ] = useState({})
  const [ error, setError ] = useState()
  const [ isFetching, setIsFetching ] = useState(false)
  const [ isCancelled, setIsCancelled ] = useState(false)

  // Runs once on mount, and cancels async requests on unmount
  useEffect(() => {
    logs.push({
      msg: 'Initial Load',
      data: { queryParams },
      timestamp: new Date().toISOString(),
    })
    if (queryParams.code) {
      const checkSsoState = async () => {
        const [ getErr, ssoState ] = await to(localStore.getItem(LOCAL_STORAGE_KEYS.SSO))
        if (getErr) {
          console.error('Unable to get saved SSO state', getErr)
          logs.push({
            msg: 'Unable to get saved SSO state',
            timestamp: new Date().toISOString(),
            isError: true,
          })
          setError({ statusCode: 'custom', message: 'There was a problem verifying your log in attempt. Please try again.' })
          return
        }

        const now = Date.now()
        const ssoName = queryParams.sso || SSOS.CLASS_LINK
        const isValidSsoState = (!!queryParams.state && !!ssoState && ssoState.nonce === queryParams.state && ssoState.sso === ssoName && now <= ssoState.expires) || (!queryParams.state)
        logs.push({
          msg: 'Got SSO state',
          data: {
            ssoName, ssoState, isValidSsoState,
          },
          timestamp: new Date().toISOString(),
        })
        if (isValidSsoState) {
          handleLogin.current({ code: queryParams.code, ssoName })
        } else {
          const [ removeErr ] = await to(localStore.removeItem(LOCAL_STORAGE_KEYS.SSO))
          if (removeErr) {
            console.error('Unable to remove saved SSO state', removeErr)
            logs.push({
              msg: 'Unable to remove saved SSO state',
              timestamp: new Date().toISOString(),
              isError: true,
            })
          }
          setError({ statusCode: 'custom', message: `Your attempt to log in with ${ssoName} failed. Please try again.` })
        }
      }
      checkSsoState()
    } else if (queryParams.auth) {
      const authStr = window.atob(queryParams.auth)
      let authObj
      try {
        authObj = JSON.parse(authStr)
      } catch (ex) {
        setInitialLoad(false)
        console.error('Unable to parse auth token from query params', ex)
        setError({ statusCode: 'custom', message: 'Your attempt to impersonate someone else failed. Please try again.' })
        return
      }
      if (!authObj.access_token) {
        // if the parsed object is not in the format we expect, redirect to the login page without the token query param
        window.location.href = window.location.origin
        return
      }
      const [ userId, orgId, roleType ] = authObj.access_token.split(':')
      const authToSave = {
        accessToken: authObj.access_token,
        refreshToken: authObj.refresh_token,
        userId,
        orgId,
        roleType,
        expires: Date.now() + (authObj.expires_in * 1000),
      }
      localStore.setItem(LOCAL_STORAGE_KEYS.AUTH, authToSave)
      setAuth(authToSave)
      setInitialLoad(false)
      return
    } else if (queryParams.err) {
      // TODO: implement some of this logic on the 7M unified login page
      // if (queryParams.err === 'auth') {
      //   setError({ statusCode: 401, message: 'session expired' })
      // } else if (queryParams.err === 'token') {
      //   setError({ statusCode: 'custom', message: 'It appears you attempted to access BASE with a bad or malicious link. Please avoid using that link to ensure you are accessing BASE securely. Instead, use this form to log in directly to BASE.' })
      // } else if (queryParams.err === 'storage') {
      //   setError({ statusCode: 'custom', message: 'It appears your browser cookies/storage may be blocked or full. Please clean or enable cookies in your browser settings to use BASE.' })
      // } else {
      //   setError({ statusCode: 'custom', message: 'There was an unknown error. Please try again in a few minutes.' })
      // }
      console.error('BASE error code: ' + queryParams.err)
      window.location.href = `${config.unifiedLoginUrl}?baseError=${queryParams.err}`
      return
    } else if (!queryParams.debug) {
      window.location.href = config.unifiedLoginUrl
      return
    }
    const checkSavedAuth = async () => {
      const [ getErr, savedAuth ] = await to(localStore.getItem(LOCAL_STORAGE_KEYS.AUTH))
      if (getErr) {
        console.error('Unable to get saved auth state', getErr)
      } else if (savedAuth && savedAuth.expires > Date.now()) {
        setAuth(savedAuth)
      }
      setInitialLoad(false)
    }
    checkSavedAuth()
    return function cleanUp () {
      setIsCancelled(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLogin = useRef((credentials) => {
    if (isCancelled) {
      return
    }
    setError(null)
    setAuth({})
    setIsFetching(true)
    const loginFunc = (credentials.code && credentials.ssoName) ? loginViaSSO : login
    logs.push({
      msg: 'Login Attempt',
      credentials: omit(credentials, 'password'),
      timestamp: new Date().toISOString(),
    })
    loginFunc(credentials)
      .then((auth) => {
        if (isCancelled) {
          return
        }
        return localStore.setItem(LOCAL_STORAGE_KEYS.AUTH, auth)
          .then(() => {
            if (isCancelled) {
              return
            }
            setIsFetching(false)
            setAuth(auth)
          })
      })
      .catch((ex) => {
        if (isCancelled) {
          return
        }
        setIsFetching(false)
        setError(ex)
      })
  })

  const errorStatus = get(error, 'statusCode')
  const errorMessage = get(error, 'message')
  const showAuthExpiredError = !!error && error.message === 'session expired'
  if ((errorStatus || errorMessage) && !!logs.length && !showAuthExpiredError) {
    logs.push({
      msg: 'Remote Error',
      errorStatus,
      errorMessage,
      timestamp: new Date().toISOString(),
      isError: true,
    })
    postLogs(logs)
    logs = []
  }
  if (isAuthenticated(auth) && (COURSE_ONLY_ROLE_TYPES.includes(auth.roleType) || queryParams.launch_course_id)) {
    launchApp(APPS.COURSE, auth, queryParams.launch_course_id)
  }

  return (
    <div className='login-page'>
      {(initialLoad || (isAuthenticated(auth) && COURSE_ONLY_ROLE_TYPES.includes(auth.roleType))) && (
        <FullScreenLoadingOverlay isActive={true}/>
      )}
      {(isAuthenticated(auth) && !COURSE_ONLY_ROLE_TYPES.includes(auth.roleType)) && (
        <div className='login-selection'>
          <Header as='h2'>Where would you like to go?</Header>
          <div className='selection-button-group'>
            <div
              tabIndex={0}
              className='selection-button'
              onKeyDown={(e) => (KEY_CODES.includes(e.keyCode)) && launchApp(APPS.ADMIN, auth)}
              onClick={() => {
                setSelectedApp(APPS.ADMIN)
                launchApp(APPS.ADMIN, auth)
              }}
            >
              <Segment loading={selectedApp === APPS.ADMIN}>
                <Header icon>
                  <Icon name='id card outline' />
                  Admin Portal
                  <Header.Subheader>
                    Manage your students/staff, create rooms for them to join, and view their progress and responses in modules
                  </Header.Subheader>
                </Header>
              </Segment>
            </div>
            <div className='divider'>OR</div>
            <div
              tabIndex={0}
              className='selection-button'
              onKeyDown={(e) => (KEY_CODES.includes(e.keyCode)) && launchApp(APPS.COURSE, auth)}
              onClick={() => {
                setSelectedApp(APPS.COURSE)
                launchApp(APPS.COURSE, auth)
              }}
            >
              <Segment loading={selectedApp === APPS.COURSE}>
                <Header icon>
                  <Icon name='student' />
                  Learner Portal
                  <Header.Subheader>
                    Take modules for professional development or preview/present the modules that your students are taking
                  </Header.Subheader>
                </Header>
              </Segment>
            </div>
          </div>
          <br/>
          <Button
            icon
            color='black'
            onClick={() => {
              localStore.removeItem(LOCAL_STORAGE_KEYS.AUTH)
                .then(() => setInitialLoad(true))
                .then(() => setAuth({}))
                .then(() => {
                  window.location.href = window.location.origin
                })
            }}
          >
            <Icon name='log out' /> Log Out
          </Button>
        </div>
      )}
      {(!isAuthenticated(auth)) && (
        <>
          {/* <Message data-public icon warning style={{ marginTop: 10, marginBottom: -20 }}>
            <Icon name='warning sign' />
            <Message.Content>
              <Message.Header>
                BASE Status
              </Message.Header>
              <p>We are currently experiencing an outage with our service provider (AWS). We apologize for the inconvenience and are working to resolve the issues as soon as we can. We appreciate your patience and will provide updates as soon as possible. For additional information, please email support@base.education</p>
            </Message.Content>
          </Message> */}
          <LoginForm
            onLogin={handleLogin.current}
            isLoading={isFetching}
            showInvalidCredentialsError={errorStatus === 400 && errorMessage.includes('status code 400')}
            showNotAllowedError={errorStatus === 403 && errorMessage.includes('currently inactive')}
            showRestrictedError={errorStatus === 403 && !errorMessage.includes('currently inactive')}
            showStudentsNotAllowedError={errorStatus === 418}
            showNotConnectedError={errorStatus === 0}
            showAuthExpiredError={showAuthExpiredError || errorStatus === 401}
            customError={errorMessage}
          />
          <Message data-public icon>
            <Icon name='info circle' />
            <Message.Content>
              <Message.Header>
                {t('views.login.forgot_password')}
              </Message.Header>
              <p><a href={`#${ResetPasswordVeiw.path}`}>{t('views.login.click_here')}</a></p>
            </Message.Content>
          </Message>
          <br/>
          <Message data-public icon>
            <Icon name='help circle' />
            <Message.Content>
              <Message.Header>
                {t('views.login.more_help_header')}
              </Message.Header>
              <p>{t('views.login.more_help')} <a href='mailto:support@base.education'>support@base.education</a></p>
            </Message.Content>
          </Message>
          <div className='sso-container' style={{ display: 'block' }}>
            <Image fluid as='button' src='https://files.readme.io/0e6a756-LogInWithClever.png' onClick={() => {
              const ssoState = {
                sso: SSOS.CLEVER,
                nonce: Math.random().toString().replace('.', '') + Date.now(),
                expires: Date.now() + (1000 * 60 * 5),
              }
              localStore.setItem(LOCAL_STORAGE_KEYS.SSO, ssoState).then(() => {
                const redirectUrl = encodeURI(`${window.location.origin}?sso=Clever`)
                window.location.href = `https://clever.com/oauth/authorize?redirect_uri=${redirectUrl}&client_id=${SSO_CLIENT_IDS[SSOS.CLEVER]}&response_type=code&state=${ssoState.nonce}`
              }).catch((ex) => {
                console.error('Unable to save Clever SSO state')
              })
            }} />
          </div>
          <div className='sso-container'>
            <Image fluid as='button' src='https://assets.website-files.com/5d6db64572061db9c481aaeb/5dee6fdc2cdb07e9e78054ca_login-button-large.png' onClick={() => {
              const ssoState = {
                sso: SSOS.CLASS_LINK,
                nonce: Math.random().toString().replace('.', '') + Date.now(),
                expires: Date.now() + (1000 * 60 * 5),
              }
              localStore.setItem(LOCAL_STORAGE_KEYS.SSO, ssoState).then(() => {
                const redirectUrl = encodeURI(window.location.origin)
                window.location.href = `https://launchpad.classlink.com/oauth2/v2/auth?scope=profile&redirect_uri=${redirectUrl}&client_id=${SSO_CLIENT_IDS[SSOS.CLASS_LINK]}&response_type=code&state=${ssoState.nonce}`
              }).catch((ex) => {
                console.error('Unable to save ClassLink SSO state')
              })
            }} />
          </div>
          <div className='google-sso-container'>
            <GoogleLogin
              onSuccess={async (credentialResponse) => {
                handleLogin.current({ code: credentialResponse.credential, ssoName: 'google' })
              }}
              onError={() => console.error('Google Login Failure')}
              width={183}
            />
          </div>
          <div className='about-us-container'>
            <Popup
              hoverable
              wide
              position='top center'
              on={[ 'hover', 'focus' ]}
              content={(
                <p>
                  For an introduction to who we are, why we are so passionate about students, and what we can do to help you meet the SEL needs of your students, visit our website @ <a href='https://base.education' rel='noopener noreferrer' target='_blank'>base.education</a>.
                </p>
              )}
              trigger={(
                <Label tabIndex={0} basic>
                  <Icon name='info' /> About Us
                </Label>
              )}
            />
          </div>
          <br/>
        </>
      )}

    </div>
  )
}

LoginView.propTypes = {
  t: PropTypes.func,
}

const LoginViewContainer = withTranslation([ 'components' ])(LoginView)

LoginView.path = '/'
LoginViewContainer.path = '/'

export default LoginViewContainer

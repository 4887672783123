import path from 'path'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import moment from 'moment'
// need to preload any locales that the app uses
// eslint-disable-next-line no-unused-vars
import esLocalization from 'moment/locale/es'
import { set, get, intersection } from 'lodash'
import { titleCase } from 'i18next-change-case-post-processor'
import intervalPlural from 'i18next-intervalplural-postprocessor'

const resources = {
  en: {},
  es: {},
}

// Jest doesn't like 'require.context' and we don't need the translations for tests
if (process.env.NODE_ENV !== 'test') {
  const req = require.context('./', true, /\.lang\.(en|es)\.json$/)
  req.keys()
    .sort()
    .map((fileName) => {
      const directory = path.dirname(fileName)
      const baseFileNameParts = path.basename(fileName, '.json').split('.')
      const lang = baseFileNameParts.pop()
      let objPath

      if (directory === '.') {
        objPath = baseFileNameParts[0]
      } else {
        objPath = directory.replace(new RegExp(`^\\.${path.sep}`), '')
          .split(path.sep)
          .join('.')
      }
      const res = req(fileName)
      return {
        objPath, lang, res,
      }
    })
    .forEach(({
      objPath, lang, res,
    }) => {
      if (objPath) {
        const existingValue = get(resources[lang], objPath)
        if (existingValue) {
          const dupTranslationKeys = intersection(Object.keys(existingValue), Object.keys(res))
          if (dupTranslationKeys.length) {
            throw Error('Duplicate translation keys: ' + dupTranslationKeys.join(', '))
          }
          res = Object.assign({}, existingValue, res)
        }
        set(resources[lang], objPath, res)
      }
    })
}

i18n
  .use(LanguageDetector)
  .use(titleCase)
  .use(intervalPlural)
  .init({
    resources,
    fallbackLng: 'en',

    // have a common namespace used around the full app
    ns: [ 'components' ],
    defaultNS: 'components',

    keySeparator: '.',

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
      format: function (value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase()
        if (format === 'lowercase') return value.toLowerCase()
        if (value instanceof Date) return moment(value).format(format)
        return value
      },
    },

    react: {
      wait: false,
      // these options are set to false for perf reasons
      // https://github.com/i18next/react-i18next/issues/456
      bindStore: false,
      bindI18n: false,
    },
  })

moment.locale(i18n.language)

// disable this for now since we are no longer firing the languageChanged event for perf reasons
// i18n.on('languageChanged', function (lng) {
//   moment.locale(lng)
// })

export default i18n
